const version = "?v=0.02"
// bottom
export const url_bottom_address = require("@/assets/images/bottom/file_address.png")
export const url_bottom_copyright = require("@/assets/images/bottom/file_copyright.png")
export const url_bottom_email = require("@/assets/images/bottom/file_email.png")
export const url_bottom_icon_address = require("@/assets/images/bottom/file_icon-address.png")
export const url_bottom_icon_phone = require("@/assets/images/bottom/file_icon-phone.png")
export const url_bottom_redbook = require("@/assets/images/bottom/file_redbook.png")
export const url_bottom_social = require("@/assets/images/bottom/file_social.png")
export const url_bottom_wechat = require("@/assets/images/bottom/file_wechat.png")

// case
export const url_case_default_phone = require("@/assets/images/case/default-phone.gif")
export const url_case_default = require("@/assets/images/case/default.gif")

export const url_case_dickies_phone = require("@/assets/images/case/dickies-phone.gif")
export const url_case_dickies = require("@/assets/images/case/dickies.gif")
export const url_case_dior_phone = require("@/assets/images/case/dior-phone.gif")
export const url_case_dior = require("@/assets/images/case/dior.gif")
export const url_case_GM_phone = require("@/assets/images/case/GM-phone.gif")
export const url_case_GM = require("@/assets/images/case/GM.gif")
export const url_case_Martell_phone = require("@/assets/images/case/Martell-phone.gif")
export const url_case_Martell = require("@/assets/images/case/Martell.gif")
export const url_case_Tiffany_phone = require("@/assets/images/case/Tiffany-phone.gif")
export const url_case_Tiffany = require("@/assets/images/case/Tiffany.gif")

export const url_case_vw = require("@/assets/images/case/vw.gif")
export const url_case_vw_phone = require("@/assets/images/case/vw-phone.gif")
export const url_case_chaumet = require("@/assets/images/case/chaumet.gif")
export const url_case_chaumet_phone = require("@/assets/images/case/chaumet-phone.gif")
export const url_case_delvaux = require("@/assets/images/case/delvaux.gif")
export const url_case_delvaux_phone = require("@/assets/images/case/delvaux-phone.gif")
export const url_case_dg = require("@/assets/images/case/dg.gif")
export const url_case_dg_phone = require("@/assets/images/case/dg-phone.gif")
export const url_case_valorant = require("@/assets/images/case/valorant.gif")
export const url_case_valorant_phone = require("@/assets/images/case/valorant-phone.gif")
export const url_case_hw = require("@/assets/images/case/hw.gif")
export const url_case_hw_phone = require("@/assets/images/case/hw-phone.gif")

// common
export const url_common_arrow_70 = require("@/assets/images/common/file_arrow-70.png")
export const url_common_arrow_100 = require("@/assets/images/common/file_arrow-100.png")
export const url_common_arrow_right_top = require("@/assets/images/common/file_arrow-right-top.png")
export const url_common_arrow_right = require("@/assets/images/common/file_arrow-right.png")
export const url_common_btn_apply_def = require("@/assets/images/common/file_btn-apply-def.png")

export const url_common_btn_apply = require("@/assets/images/common/file_btn-apply.png")
export const url_common_btn_back = require("@/assets/images/common/file_btn-back.png")
export const url_common_btn_close = require("@/assets/images/common/file_btn-close.png")
export const url_common_btn_confirm_def = require("@/assets/images/common/file_btn-confirm-def.png")
export const url_common_btn_confirm = require("@/assets/images/common/file_btn-confirm.png")

export const url_common_btn_contact_sel = require("@/assets/images/common/file_btn-contact-sel.png")
export const url_common_btn_contact = require("@/assets/images/common/file_btn-contact.png")
export const url_common_btn_enter_base_def = require("@/assets/images/common/file_btn-enter-base-def.png")
export const url_common_btn_enter_base_sel = require("@/assets/images/common/file_btn-enter-base-sel.png")
export const url_common_btn_enter_default = require("@/assets/images/common/file_btn-enter-default.png")

export const url_common_btn_enter_select = require("@/assets/images/common/file_btn-enter-select.png")
export const url_common_btn_jobs_sel = require("@/assets/images/common/file_btn-jobs-sel.png")
export const url_common_btn_jobs = require("@/assets/images/common/file_btn-jobs.png")
export const url_common_btn_menu = require("@/assets/images/common/file_btn-menu.png")
export const url_common_btn_replay_select = require("@/assets/images/common/btn-replay-select.png")

export const url_common_btn_replay = require("@/assets/images/common/btn-replay.png")
export const url_common_btn_service_sel = require("@/assets/images/common/file_btn-service-sel.png")
export const url_common_btn_service = require("@/assets/images/common/file_btn-service.png")
export const url_common_btn_skip = require("@/assets/images/common/file_btn-skip.png")
export const url_common_btn_start_exploring_def = require("@/assets/images/common/file_btn-start-exploring-def.png")

export const url_common_btn_start_exploring_sel = require("@/assets/images/common/file_btn-start-exploring-sel.png")
export const url_common_btn_submit_def = require("@/assets/images/common/file_btn-submit-def.png")
export const url_common_btn_submit_sel = require("@/assets/images/common/file_btn-submit-sel.png")
export const url_common_china = require("@/assets/images/common/file_china.png")
export const url_common_icon_add = require("@/assets/images/common/file_icon-add.png")

export const url_common_icon_logo = require("@/assets/images/common/notion-logo-3.gif")
export const url_common_korea = require("@/assets/images/common/file_korea.png")
export const url_common_learn_more = require("@/assets/images/common/file_learn_more.png")
export const url_common_notionbase = require("@/assets/images/common/file_notionbase.png")
export const url_common_singapore = require("@/assets/images/common/file_singapore.png")
export const url_common_view_more = require("@/assets/images/common/file_view_more.png")

export const url_common_next_chpt = require("@/assets/images/common/next-chpt.png")
export const url_common_next_chpt_sel = require("@/assets/images/common/next-chpt-select.png")

export const url_common_arrow_next = require("@/assets/images/common/arrow-next.gif")

// index
export const url_index_astronaut = require("@/assets/images/index/file_astronaut.png")
export const url_index_bg_page_6_small = require("@/assets/images/index/file_bg-page-6-small.png")
export const url_index_bg_page_6 = require("@/assets/images/index/bg-page-06-1.png")
export const url_index_bg_page_7 = require("@/assets/images/index/file_bg-page-7.jpg")
export const url_index_bg_page_8 = require("@/assets/images/index/file_bg-page-8.jpg")
export const url_index_computer = require("@/assets/images/index/file_computer.png")

export const url_index_phone = require("@/assets/images/index/file_phone.png")
export const url_index_spaceman = require("@/assets/images/index/file_spaceman.png")
export const url_index_test_footprints = require("@/assets/images/index/file_test-footprints.png")
export const url_index_test_touch = require("@/assets/images/index/file_test-touch.png")
export const url_index_top_black = require("@/assets/images/index/file_top-black.png")

// logo
export const url_logo_bottom = require("@/assets/images/logo/file_bottom.png")
export const url_logo_thanks = require("@/assets/images/logo/file_thanks.png")

// menu
export const url_menu_client_gif = require("@/assets/images/menu/client.gif")
export const url_menu_home_gif = require("@/assets/images/menu/home.gif")
export const url_menu_contact_gif = require("@/assets/images/menu/contact.gif")
export const url_menu_project_gif = require("@/assets/images/menu/project.gif")

export const url_menu_client = require("@/assets/images/menu/file_client.png")
export const url_menu_home = require("@/assets/images/menu/file_home.png")
export const url_menu_contact = require("@/assets/images/menu/file_contact.png")
export const url_menu_project = require("@/assets/images/menu/file_project.png")
export const url_menu_icon_mute = require("@/assets/images/menu/file_icon-mute.png")
export const url_menu_icon_volume = require("@/assets/images/menu/file_icon-volume.png")

// social
export const url_social_redbook = require("@/assets/images/social/file_redbook.png")
export const url_social_linkedin = require("@/assets/images/social/file_linkedin.png")
export const url_social_wechat = require("@/assets/images/social/file_wechat.png")